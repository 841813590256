
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ChatMessage from '@/models/ChatMessage';
import { UserRepository } from '@/repositories/UserRepository';
import ActivityMessageSingleCol from '@/components/views/ChatView/ActivityMessageSingleCol.vue';

@Component({
    name: 'ProjectViewSidebarMessagesCardMsgBubble',
    components: {
        SidebarCard,
        ActivityMessageSingleCol,
    },
})
export default class ProjectViewSidebarMessagesCardMsgBubble extends Vue {
    @Prop({ required: true }) private message!: ChatMessage;
    @Prop({ required: true }) private selectedProjectId!: null | string;

    private get isPlainMsg() {
        return this.message.content?.text;
    }

    private get isActivity() {
      // @ts-ignore
      return this.message.nextStep?.type?.name && this.message.nextStep?.date;
    }

    private get isMadeByUserSameAsCurrentUser() {
        const currentUser = UserRepository.getCurrentUser();
        if (currentUser == null || this.message.madeByUser == null) {
            return false;
        }

        return currentUser.id === this.message.madeByUser.id;
    }
}
