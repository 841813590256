
// @ts-nocheck
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ProjectViewSidebarMessagesCardMsgBubble from '@/components/global/sidebar/ProjectViewSidebarMessagesCardMsgBubble.vue';
import Project from '@/models/Project';
import ChatRepository from '@/repositories/ChatRepository';
import { RouteNames } from '@/enums/routes/RouteNames';
import i18n from '@/i18n';
import { saveChatMessage } from '@/helpers/chat/ChatHelper';
import SingleNextStepForm from '../SingleNextStepForm.vue';
import tippy, { sticky } from 'tippy.js';
import { Editor, EditorContent } from 'tiptap';
import { Mention } from 'tiptap-extensions';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import CMSUser from '@/models/CMSUser';
import Fuse from 'fuse.js';

@Component({
    name: 'ProjectViewSidebarMessagesCard',
    components: {
        SidebarCard,
        ProjectViewSidebarMessagesCardMsgBubble,
        EditorContent,
    },
})
export default class ProjectViewSidebarMessagesCard extends Vue {
    @Prop({ required: true }) private project!: Project;

    private messagesShown = true;
    private newMessage = '';
    private haveUsersBeenFetched = false;
    private query = null;
    private suggestionRange = null;
    private filteredUsers = [];
    private navigatedUserIndex = 0;
    private popup = null;
    private insertMention = null;

    private editor = new Editor({
        extensions: [
            // new BulletList(),
            // new OrderedList(),
            // new ListItem(),
            // new Bold(),
            // new Link(),
            // new History(),
            // new Placeholder({
            //     emptyNodeText: this.$t('Upišite poruku ovdje'),
            // }),
            new Mention({
                // a list of all suggested items
                items: async () => {
                    if (!this.haveUsersBeenFetched) {
                        this.haveUsersBeenFetched = true;
                        await CMSUser.getAll();
                    }
                    return CMSUserRepository.getUsersForMentions();
                },
                // is called when a suggestion starts
                // @ts-ignore
                onEnter: ({ items, query, range, command, virtualNode }) => {
                    this.updateData({
                        query,
                        items,
                        range,
                        command,
                    });
                    // this.query = query;
                    // this.filteredUsers = items;
                    // this.suggestionRange = range;
                    this.renderPopup(virtualNode);
                    // we save the command for inserting a selected mention
                    // this allows us to call it inside of our custom popup
                    // via keyboard navigation and on click
                    // this.insertMention = command;
                },
                // is called when a suggestion has changed
                // @ts-ignore
                onChange: ({ items, query, range, virtualNode }) => {
                    // this.query = query;
                    // this.filteredUsers = items;
                    // this.suggestionRange = range;
                    // this.navigatedUserIndex = 0;
                    this.updateData({
                        query,
                        items,
                        range,
                        navigatedUserIndex: 0,
                    });
                    this.renderPopup(virtualNode);
                },
                // is called when a suggestion is cancelled
                onExit: () => {
                    // reset all saved values
                    // this.query = null;
                    // this.filteredUsers = [];
                    // this.suggestionRange = null;
                    // this.navigatedUserIndex = 0;
                    this.updateData({
                        query: null,
                        items: [],
                        range: null,
                        navigatedUserIndex: 0,
                    });
                    this.destroyPopup();
                },
                // is called on every keyDown event while a suggestion is active
                onKeyDown: ({ event }) => {
                    if (event.key === 'ArrowUp') {
                        this.upHandler();
                        return true;
                    }
                    if (event.key === 'ArrowDown') {
                        this.downHandler();
                        return true;
                    }
                    if (event.key === 'Enter') {
                        this.enterHandler();
                        return true;
                    }
                    return false;
                },
                // is called when a suggestion has changed
                // this function is optional because there is basic filtering built-in
                // you can overwrite it if you prefer your own filtering
                // in this example we use fuse.js with support for fuzzy search
                onFilter: async (items, query) => {
                    if (!query) {
                        return items;
                    }
                    await new Promise((resolve) => {
                        setTimeout(resolve, 500);
                    });
                    const fuse = new Fuse(items, {
                        threshold: 0.2,
                        keys: ['name'],
                    });
                    return fuse.search(query).map((item) => item.item);
                },
            }),
        ],
        content: this.newMessage,
        onUpdate: this.onUpdate,
    });

    private async onSendMessage() {
        try {
            this.$emit('start-loading');
            await saveChatMessage({
                chatId: this.project?.chat?.id ?? null,
                selectedProjectId: this.project?.id,
                content: this.newMessage,
                attachments: [],
                singleNextStepFormReference: (undefined as unknown) as SingleNextStepForm,
                shouldCreateNextStep: false,
            });
            this.$emit('stop-loading');
        } catch (e) {
            this.$notification.error({
                message: i18n.t('Došlo je do greške') as string,
                description: (e as Error).message,
            });
            return;
        }
        this.newMessage = '';
        this.editor.clearContent(true);
    }

    private onUpdate({ getHTML }) {
        const html = this.fixEmptyNewlines(getHTML());
        this.newMessage = html;
    }

    private fixEmptyNewlines(html) {
        return html.replace(/<p><\/p>/gim, '<p>&nbsp;</p>');
    }

    private updateData(data) {
        if (data.hasOwnProperty('items')) {
            this.filteredUsers = data.items;
        }
        if (data.hasOwnProperty('query')) {
            this.query = data.query;
        }
        if (data.hasOwnProperty('range')) {
            this.suggestionRange = data.range;
        }
        if (data.hasOwnProperty('index')) {
            this.navigatedUserIndex = data.index;
        }
        if (data.hasOwnProperty('command')) {
            this.insertMention = data.command;
        }
    }

    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    private renderPopup(node: any) {
        const { x, y } = node.getBoundingClientRect();

        if (x === 0 && y === 0) {
            return;
        }

        if (this.popup) {
            return;
        }

        // ref: https://atomiks.github.io/tippyjs/v6/all-props/
        this.popup = tippy('#app', {
            getReferenceClientRect: () => node.getBoundingClientRect(),
            appendTo: () => document.body,
            interactive: true,
            sticky: true, // make sure position of tippy is updated when content changes
            plugins: [sticky],
            content: this.$refs.suggestions,
            trigger: 'mouseenter', // manual
            showOnCreate: true,
            theme: 'dark',
            placement: 'top-start',
            inertia: true,
            duration: [400, 200],
        });
    }

    private upHandler() {
        this.navigatedUserIndex = (this.navigatedUserIndex + this.filteredUsers.length - 1) % this.filteredUsers.length;
    }

    // navigate to the next item
    // if it's the last item, navigate to the first one
    private downHandler() {
        this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length;
    }

    private enterHandler() {
        const user = this.filteredUsers[this.navigatedUserIndex];

        if (user) {
            this.selectUser(user);
        }
    }

    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    private selectUser(user) {
        this.insertMention({
            range: this.suggestionRange,
            attrs: {
                id: user.id,
                label: user.name,
            },
        });
        this.$emit('onSelectUser', user.id);
        this.editor.focus();
    }

    private destroyPopup() {
        if (this.popup) {
            this.popup[0].destroy();
            this.popup = null;
        }
    }

    private get messages() {
        if (this.project == null || this.project.chat == null) {
            return [];
        }

        return ChatRepository.getMessagedFromChatById(this.project.chat.id);
    }

    private get numberOfChatMessages() {
        return this.messages.length;
    }

    private openChat() {
        if (!this.project?.id) {
            return;
        }

        this.$router.push({
            name: RouteNames.chat,
            params: {
                id: this.project.id,
            },
        });
    }
}
