
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import Project from '@/models/Project';
import { generatePricelist } from '@/helpers/project/ProjectHelper';
import { UserRepository } from '@/repositories/UserRepository';
import OfferRepository from '@/repositories/OfferRepository';
import { UserRightsEnum } from '@/enums/global/UserRights';

@Component({
    name: 'ProjectViewSidebarPriceCard',
    components: {
        SidebarCard,
    },
})
export default class ProjectViewSidebarPriceCard extends Vue {
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private basicProjectData!: any;

    private purchasePriceDetailsShown = false;
    private salePriceDetailsShown = false;

    private removeLastItem(arr: unknown): unknown[] {
        if (!Array.isArray(arr)) {
            return [];
        }
        const copy = arr.slice();
        copy.pop();
        return copy;
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get currentUserGroup() {
        return String(this.$store.getters['jwtData/currentUserGroup']);
    }

    private get canUserViewPurchasePrice() {
        return this.userRights.includes(UserRightsEnum.VIEW_PURCHASE_PRICE);
    }

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private get purchasePriceTotal() {
        if (!this.purchasePriceList.length) {
            return null;
        }
        return this.purchasePriceList[this.purchasePriceList.length - 1];
    }

    private get sellingPriceTotal() {
        if (!this.sellingPriceList.length) {
            return null;
        }
        return this.sellingPriceList[this.sellingPriceList.length - 1];
    }

    private get selectedOffer() {
        const offerList = OfferRepository.getProjectOffers(this.project?.projectId ?? this.basicProjectData.id);
        return offerList[0];
    }

    private get sellingPriceList() {
        if (this.selectedOffer == null) {
            return [];
        }

        const a = generatePricelist(this.selectedOffer, this.currencySymbol, true, this.currentUserGroup);
        return a;
    }

    private get purchasePriceList() {
        if (this.selectedOffer == null) {
            return [];
        }

        const a = generatePricelist(this.selectedOffer, this.currencySymbol, false, this.currentUserGroup);
        return a;
    }
}
