
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ChatMessageList from '@/components/views/ChatView/ChatMessageList.vue';
import Project from '@/models/Project';
import NextStep from '@/models/NextStep';
import ActivityNewModal from '@/components/global/ActivityNewModal.vue';
import ActivityUpdateModal from '@/components/global/ActivityUpdateModal.vue';
import store from '@/store';
import { UserRightsEnum } from '@/enums/global/UserRights';

@Component({
    name: 'ProjectViewSidebarActivitiesCard',
    components: {
        SidebarCard,
        ChatMessageList,
        ActivityNewModal,
        ActivityUpdateModal,
    },
})
export default class ProjectViewSidebarActivitiesCard extends Vue {
    @Prop({ required: true }) private project!: Project;
    @Prop({ default: false }) private lockEditing!: boolean;

    private doneActivitiesShown = true;
    private isActivityNewModalVisible = false;
    private isActivityUpdateModalVisible = false;
    private updateActivity: NextStep | null = null;

    private get selectedProjectId() {
        return this.project?.id;
    }

    private get projectLead() {
        if (this.project) {
            return this.project.projectLead;
        }
    }

    private get canEditActivities() {
        const userRights = store.getters['jwtData/userRights'];
        const canSeeEditNextSteps = userRights.includes(UserRightsEnum.EDIT_NEXT_STEPS);
        return canSeeEditNextSteps;
    }

    private get canDeleteActivity() {
        const userRights = store.getters['jwtData/userRights'];
        const canSeeDeleteNextSteps = userRights.includes(UserRightsEnum.DELETE_NEXT_STEPS);
        return canSeeDeleteNextSteps;
    }

    private get nextSteps() {
        if (!this.project || !this.projectLead) {
            return [];
        }

        return this.project.nextSteps.sort((a: any, b: any) => {
            return new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime();
        });
    }

    private get visibleActivities() {
        if (this.doneActivitiesShown) {
            return this.nextSteps;
        }

        return this.nextSteps.filter((step) => !step.completed);
    }

    private get numberOfActivities() {
        return this.nextSteps.length;
    }

    private onAddActivity() {
        this.isActivityNewModalVisible = true;
    }

    private async onToggleAvtivityCompleted(activity: NextStep) {
        try {
            this.$emit('start-loading');
            await NextStep.updateCompletedState(activity.id, activity.completed);
            this.$emit('stop-loading');
        } catch (error) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: '',
            });
            return;
        }

        this.$notification.success({
            message: this.$t('Aktivnost je uspješno spremljena'),
            description: ``,
        });
    }

    private onEditActivity(activity: NextStep) {
        this.updateActivity = activity;
        this.$nextTick(() => {
            this.isActivityUpdateModalVisible = true;
        });
    }
}
