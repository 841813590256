
import { Component, Prop, Vue } from 'vue-property-decorator';
import NextStep from '@/models/NextStep';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';

@Component({
    name: 'ActivityMessageSingleCol',
    components: {},
})
export default class ActivityMessageSingleCol extends Vue {
    @Prop({ required: true }) private activity!: NextStep;
    @Prop({ required: true }) private selectedProjectId!: null | string;

    private routeNames = RouteNames;
    private projectTabValues = ProjectTabValues;
}
