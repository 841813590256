var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('sidebar-card',[_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showDropzone),expression:"!showDropzone"}],on:{"dragover":function($event){return _vm.toggleDropzone(true)}}},[_c('h3',{staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.$t('Prilozi'))+" ")]),(!_vm.lockEditing)?_c('a-upload',{staticStyle:{"float":"right","margin-top":"7px","cursor":"pointer"},attrs:{"file-list":[],"beforeUpload":_vm.prepareUploadingAttachments,"customRequest":() => {
                        return;
                    },"multiple":true}},[_c('a-icon',{attrs:{"type":"plus"}})],1):_vm._e(),_vm._l((_vm.attachments),function(attachment){return _c('a-row',{key:attachment.id},[_c('a-col',{attrs:{"span":22}},[_c('a-button',{staticClass:"attachment-button",attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.onPreview(attachment)}}},[_c('a-icon',{attrs:{"type":"file"}}),_c('span',{staticClass:"text-dots"},[_vm._v(_vm._s(attachment.name))])],1)],1),_c('a-col',{attrs:{"span":2}},[_c('a-icon',{staticStyle:{"float":"right","margin-top":"3px"},attrs:{"type":"delete"},on:{"click":function($event){return _vm.onOpenRemoveModal(attachment)}}})],1)],1)})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDropzone && !_vm.lockEditing),expression:"showDropzone && !lockEditing"}],staticClass:"wrapper",on:{"dragover":function($event){return _vm.toggleDropzone(true)},"dragleave":function($event){return _vm.toggleDropzone(false)},"drop":function($event){_vm.showDropzone = false}}},[_c('UploadDragger',{attrs:{"beforeUpload":_vm.prepareUploadingAttachments,"customRequest":() => {
                        return;
                    },"fileList":[],"showUploadList":{
                    showPreviewIcon: false,
                    showDownloadIcon: false,
                },"listType":"picture","multiple":true,"name":"file"}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t('Dovucite datoteke koje želite postaviti'))+" ")])])],1)]),(_vm.uploadingAttachments)?_c('div',{staticClass:"attachments__upload-overlay"},[_c('div',{staticClass:"prograss-bar__wrapper"},[_c('a-progress',{attrs:{"percent":_vm.progressBarPercentage,"status":_vm.progressStatus,"strokeWidth":20}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }