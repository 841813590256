
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import Project from '@/models/Project';
import { UploadFile } from 'ant-design-vue/types/upload';
import { customHandlerForFileUpload, updateProjectAttachments } from '@/helpers/attachments/AttachmentsHelper';
import Attachment from '@/models/Attachment';
import { convertFileToAntdFile } from '@/helpers/CmsIndexHelper';
import Image from '@/models/Image';
// @ts-ignore
import downloadFile from 'downloadjs';
import { Upload } from 'ant-design-vue';
import { CustomRequestParameters } from '@/interfaces/general/CustomRequestParameters';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'ProjectViewSidebarAttachmentsCard',
    components: {
        UploadDragger: Upload.Dragger,
        SidebarCard,
    },
})
export default class ProjectViewSidebarAttachmentsCard extends Vue {
    @Prop({ required: true }) private project!: Project;
    @Prop({ default: false }) private lockEditing!: boolean;

    private showDropzone = false;
    private attachments: UploadFile[] = [];
    private attachmentsForUpload: UploadFile[] = [];
    private uploadingAttachments: boolean = false;
    private attachmentsUploaded = 0;
    private progressStatus = 'active';
    private totalAttachments = 0;

    // hidding of the dropzone is delayed, to prevent flickering effect when draging over different child components
    private toggleDropzineTimer: NodeJS.Timeout | undefined;

    private get progressBarPercentage() {
        return Math.round((this.attachmentsUploaded / this.totalAttachments) * 100);
    }

    private resetUploadingStateWatchers(progressStatus: string = 'active', isUploadingAttachments: boolean = false) {
        this.uploadingAttachments = isUploadingAttachments;
        this.progressStatus = progressStatus;
        this.attachmentsUploaded = 0;
    }

    private async prepareUploadingAttachments(file: UploadFile, files: UploadFile[]) {
        if (this.areAttachmentsAlreadyAdded(files)) {
            return;
        }

        this.resetUploadingStateWatchers('active', true);
        this.totalAttachments = files.length;
        this.attachmentsForUpload = files;

        this.evaluateAndUploadSelectedAttachments(files);
    }

    private areAttachmentsAlreadyAdded(files: UploadFile[]) {
        return files.every((file: UploadFile) => {
            return this.attachmentsForUpload.some((newFile: UploadFile) => file.uid === newFile.uid);
        });
    }

    private async evaluateAndUploadSelectedAttachments(files: UploadFile[]) {
        const uploadPromises = files.map((file: UploadFile) => {
            return this.customRequest({
                file: file as any,
                onSuccess: (formData: FormData) => {
                    this.attachmentsUploaded++;
                },
                onError: () => {
                    this.resetUploadingStateWatchers('exception');

                    this.$notification.error({
                        message: '',
                        description: this.$t('Error uploading attachments'),
                    });
                    return;
                },
            });
        });

        try {
            await Promise.all(uploadPromises);
        } catch {
            return Promise.reject();
        } finally {
            EventBus.$emit(EventBusEvents.updateProject);
        }

        this.progressStatus = 'success';
    }

    private stopLoadingAndFetchProject() {
        this.$emit('stop-loading');
        EventBus.$emit(EventBusEvents.updateProject);
    }

    private toggleDropzone(show: boolean) {
        if (show) {
            this.showDropzone = true;
            if (this.toggleDropzineTimer) {
                clearTimeout(this.toggleDropzineTimer);
            }
        } else {
            this.toggleDropzineTimer = setTimeout(() => {
                this.showDropzone = false;
            }, 500);
        }
    }

    private async customRequest(customRequestParameters: CustomRequestParameters) {
        await customHandlerForFileUpload(customRequestParameters, this.project.lead.id);
    }

    private onOpenRemoveModal(file: UploadFile) {
        return new Promise((resolve) => {
            this.$confirm({
                title: this.$t('Jeste li sigurni da želite primjeniti ove promjene?') as string,
                content: '',
                okText: this.$t('Da') as string,
                okType: 'danger',
                cancelText: this.$t('Ne') as string,
                class: 'c-delete-modal',
                onOk: () => {
                    this.onRemove(file);
                    resolve(null);
                },
                onCancel: () => {
                    resolve(null);
                },
            });
        });
    }

    private async onRemove(file: UploadFile) {
        this.$emit('start-loading');
        const removedFileIndex = this.attachments.findIndex((document: UploadFile) => {
            return document.uid === file.uid;
        });

        if (removedFileIndex === -1) {
            return;
        }

        Attachment.delete(file.uid);
        this.attachments.splice(removedFileIndex, 1);

        await updateProjectAttachments(this.project?.lead?.id ?? null, this.attachments);

        this.stopLoadingAndFetchProject();
    }

    private async onPreview(file: UploadFile & Image) {
        let blob;
        try {
            blob = await Attachment.getBlob(file.url as string);
        } catch (e) {
            let error;

            if (e instanceof Error) {
                error = e.message;
            } else {
                error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
            }

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error,
            });
            return;
        }
        downloadFile(blob.data, file.name);
    }

    @Watch('project', {
        deep: true,
        immediate: true,
    })
    private onProjectChange() {
        if (Array.isArray(this.project?.lead?.attachments)) {
            this.attachments = this.project.lead.attachments.map((attachment: Attachment) =>
                convertFileToAntdFile(attachment)
            );
        }

        this.resetUploadingStateWatchers();
    }
}
