import ProjectLog from '@/models/ProjectLog';

export default class ProjectLogRepository {
    public static getAll() {
        return ProjectLog.all();
    }

    public static getById(id: string) {
        return ProjectLog.query().whereId(id).withAll().first();
    }
}
