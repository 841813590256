
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ChatMessageList from '@/components/views/ChatView/ChatMessageList.vue';
import Project from '@/models/Project';
import { generateActiveStatus, generateProgressBarPercentage } from '@/helpers/NewProject/NewProjectHelper';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { Progress } from 'ant-design-vue';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import LabelReporistory from '@/repositories/LabelReporistory';
import Label from '@/models/Label';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import WorkflowReadOnlyModal from '@/components/views/project/WorkflowReadOnlyModal.vue';
import { Collection } from '@vuex-orm/core';
import ActivityNewModal from '@/components/global/ActivityNewModal.vue';
import NextStepTemplate from '@/models/NextStepTemplate';
import { AxiosResponse } from 'axios';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'ProjectViewSidebarProgressCard',
    components: {
        SidebarCard,
        ChatMessageList,
        Progress,
        WorkflowReadOnlyModal,
        ActivityNewModal,
    },
})
export default class ProjectViewSidebarProgressCard extends Vue {
    @Prop({ required: true }) private project!: Project;
    @Prop({ default: false }) private lockEditing!: boolean;
    @Prop({ required: true }) private basicProjectData!: any;

    private showProgressOptions = false;
    private isWorkflowReadOnlyModalVisible = false;
    private allAvailableLabels: Collection<Label> = [];
    private loading = false;
    private isActivityNewModalVisible = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get progressPercent() {
        if (this.project?.state === ProjectStates.LEAD) {
            return 25;
        }
        if (this.project?.state === ProjectStates.REQUEST) {
            return 50;
        }
        if (this.project?.state === ProjectStates.OFFER) {
            return 75;
        }
        if (this.project?.state === ProjectStates.ORDER) {
            return 100;
        }
        return 0;
    }

    private get projectLead() {
        if (this.project) {
            return this.project.projectLead;
        }
    }

    private onShowWorkflowModal() {
        this.showProgressOptions = false;
        this.isWorkflowReadOnlyModalVisible = true;
    }

    private get activeStatus() {
        if (!this.project && !this.basicProjectData) {
            return '';
        }

        return generateActiveStatus(
            this.project?.label?.name || this.basicProjectData?.projectLabelName,
            this.project?.orderStatus?.name || this.basicProjectData?.orderStatusName,
            (this.project?.state || this.basicProjectData?.state) === ProjectStates.REQUEST,
            this.isCurrentUserGroupRoltek
        );
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get isCurrentUserGroupRoltek() {
        return this.currentUserGroup && this.currentUserGroup.name === 'Roltek';
    }

    private async fetchStatuses() {
        this.$emit('start-loading');
        await this.fetchAllowedStatuses();
        this.$emit('stop-loading');
    }

    private async fetchAllowedStatuses() {
        if (this.project?.projectId == null) {
            return;
        }
        const response = (await Label.fetchAvailableLabels(this.project.projectId)) as AxiosResponse<Label[]>;
        this.allAvailableLabels = response.data;
    }

    private async onChangeLabel(label: Label) {
        if (!this.project.id) {
            return;
        }

        this.showProgressOptions = false;
        this.$emit('start-loading');

        try {
            // Don't delete. Waiting for BE to fix this.
            await Project.updateProjectLabel(this.project.id, label.id);
            EventBus.$emit(EventBusEvents.updateProject);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error'),
                description: this.$t('Error changing project status'),
            });
            this.$emit('stop-loading');
            return;
        }

        // FETCH NEXT STEP TEMPLATES AND OPEN ACTIVITY NEW MODAL
        this.fetchNextStepTemplatesAndOpenPopup(label.id);
    }

    // LOGIC FOR OPENING ACTIVITY NEW MODAL ON STATUS CHANGE
    private async fetchNextStepTemplatesAndOpenPopup(labelId: string) {
        let templates: AxiosResponse<NextStepTemplate[]> | undefined;

        try {
            templates = await this.fetchNextStepTemplates(labelId);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error'),
                description: this.$t('Error fetching next step templates'),
            });
            return;
        } finally {
            this.$emit('stop-loading');
        }

        if (!templates || templates.data.length === 0) {
            return;
        }

        const userId = this.getNextStepTemplateUserId(templates.data[0].responsiblePersonType);
        this.isActivityNewModalVisible = true;
        this.$nextTick(() => {
            EventBus.$emit(EventBusEvents.openActivityNewModalOnStatusChange, {
                templatesData: templates?.data,
                assignedUser: userId,
            });
        });
    }

    private async fetchNextStepTemplates(labelId: string) {
        let templates: AxiosResponse<NextStepTemplate[]> | null = null;

        try {
            templates = (await NextStepTemplate.getByLabelId(labelId)) as AxiosResponse<NextStepTemplate[]>;
        } catch (e) {
            Promise.reject(e);
        }

        if (templates) {
            return Promise.resolve(templates);
        }
    }

    private getNextStepTemplateUserId(responsiblePerson: string) {
        if (responsiblePerson === 'CREATOR') {
            return this.project?.projectMadeByUser?.id;
        }

        if (responsiblePerson === 'COMMERCIALIST') {
            return this.project?.projectAssignedUser?.id;
        }

        if (!isNaN(Number(responsiblePerson))) {
            return responsiblePerson;
        }
    }
}
