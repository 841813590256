
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ChatMessageList from '@/components/views/ChatView/ChatMessageList.vue';
import Project from '@/models/Project';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { sortDates } from '@/helpers/SortingHelper';

@Component({
    name: 'ProjectViewSidebarGeneralInfoCard',
    components: {
        SidebarCard,
        ChatMessageList,
    },
})
export default class ProjectViewSidebarGeneralInfoCard extends Vue {
    @Prop({ required: true }) private project!: Project;
    @Prop({ default: false }) private lockEditing!: boolean;
    @Prop({ required: true }) private basicProjectData!: any;
    @Prop({ required: false }) private basicStateSpecificId!: string;

    private editModeOn = false;
    private localProjectConnection = '';
    private localAssignedUser: string | null = null;
    private localMadeByUser: string | null = null;

    private get createdDate() {
        if (!this.project && !this.basicProjectData) {
            return '';
        }
        return this.project?.createdAt ?? this.project?.sortDate ?? this.basicProjectData?.createdAt ?? '';
    }

    private get projectIdText() {
        if (!this.project && !this.basicProjectData) {
            return '';
        }

        const projectState = this.project?.state ?? this.basicProjectData?.state;

        if (projectState === ProjectStates.LEAD) {
            return this.project?.id ?? this.basicProjectData?.id;
        }
        if (projectState === ProjectStates.REQUEST) {
            return this.project?.id ?? this.basicProjectData?.id;
        }
        if (projectState === ProjectStates.ORDER) {
            return this.project?.orderNumber ?? this.basicProjectData?.orderNumber;
        }
        if (projectState === ProjectStates.OFFER && this.project?.offerRevisions.length) {
            const sortedOffers = this.project.offerRevisions
                .slice()
                .sort((a, b) => sortDates(a.createdAt, b.createdAt, false));
            for (const offer of sortedOffers) {
                if (offer.name !== '-') {
                    return offer.name;
                }
            }
        }
        if (projectState === ProjectStates.OFFER && this.basicProjectData) {
            return this.basicProjectData?.offerName ?? '';
        }

        return this.project?.id ?? this.basicProjectData?.id;
    }

    private get assignedUserText(): string {
        if (!this.salesmen) {
            return '';
        }

        const user = this.salesmen.find((item) => item.id === this.localAssignedUser);

        if (!user) {
            return this.basicProjectData?.assignedUserName ?? '';
        }

        return user.name;
    }

    private get projectMadeByUserText(): string {
        if (!this.activeUsers) {
            return '';
        }

        const user = this.activeUsers.find((item) => item.id === this.localMadeByUser);
        if (!user) {
            return '';
        }

        return user.name;
    }

    private get salesmen() {
        return CMSUserRepository.getAllSalesmen();
    }

    private get activeUsers() {
        return CMSUserRepository.getAllExceptArchivedUsers();
    }

    private async onSave() {
        try {
            this.$emit('start-loading');
            await Project.updateExisting({
                projectId: String(this.project.id),
                assignedUserId: this.localAssignedUser,
                madeByUserId: this.localMadeByUser,
                connection: this.localProjectConnection,
            });
            this.editModeOn = false;
            this.$emit(EventBusEvents.updateProject);
            this.$notification.success({
                message: this.$t('Aktivnost je uspješno spremljena'),
                description: ``,
            });
            this.$emit('stop-loading');
        } catch (error) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: '',
            });
            return;
        }
    }

    private onCancel() {
        this.setLocalData();
        this.editModeOn = false;
    }

    private mounted() {
        this.setLocalData();
    }

    private setLocalData() {
        this.localProjectConnection = this.project?.connection ?? this.basicProjectData?.connection;
        this.localAssignedUser = this.project?.projectAssignedUser?.id;
        this.localMadeByUser = this.project?.projectMadeByUser?.id;
    }

    @Watch('project', {
        immediate: true,
    })
    private onProjectChange(project: Project) {
        if (this.project) {
            this.setLocalData();
        }
    }
}
