import { render, staticRenderFns } from "./ProjectViewSidebarGeneralInfoCard.vue?vue&type=template&id=2e2c7696&scoped=true"
import script from "./ProjectViewSidebarGeneralInfoCard.vue?vue&type=script&lang=ts"
export * from "./ProjectViewSidebarGeneralInfoCard.vue?vue&type=script&lang=ts"
import style0 from "./ProjectViewSidebarGeneralInfoCard.vue?vue&type=style&index=0&id=2e2c7696&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e2c7696",
  null
  
)

export default component.exports