
import { Component, Prop, Vue } from 'vue-property-decorator';
import Client from '@/models/Client';
import Project from '@/models/Project';
import Offer from '@/models/Offer';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ClientTabs } from '@/enums/components/EditClient/ClientTabs';
import { generateStateSpecificId } from '@/helpers/project/ProjectHelper';
import OfferRepository from '@/repositories/OfferRepository';

@Component({
    name: 'ProjectDynamicInfoBar',
    components: {},
})
export default class ProjectDynamicInfoBar extends Vue {
    @Prop({ required: true }) private client!: Client | null;
    @Prop({ required: true }) private project!: Project | null;
    @Prop({ default: null }) private projectId!: string;
    @Prop({ required: false }) private basicStateSpecificId!: string;
    @Prop({ required: false }) private basicProjectData!: any;

    private selectedOffer: Offer | null = null;

    private get clientRoute() {
        if (!this.client && !this.basicProjectData.clientId) {
            return '';
        }

        return {
            name: RouteNames.editClient,
            params: {
                clientId: this.client?.id ?? this.basicProjectData?.clientId,
                initialTab: ClientTabs.CUSTOMER,
            },
        };
    }

    private get stateSpecificId() {
        this.selectedOffer = OfferRepository.getSelectedOffer(this.projectId) as Offer;
        return generateStateSpecificId(this.project, this.selectedOffer);
    }

    private mounted() {
        console.log(this.stateSpecificId, 'test', this.basicStateSpecificId);
    }
}
