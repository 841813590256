
import { Component, Prop, Vue } from 'vue-property-decorator';
import WorkflowContainer from '@/components/views/Workflow/WorkflowContainer.vue';
import Label from '@/models/Label';
import { GlobalOptions } from '@/enums/global/GlobalOptions';
import { ModalWidth } from '@/enums/global/ModalWidth';

@Component({
    name: 'WorkflowReadOnlyModal',
    components: {WorkflowContainer},
})
export default class WorkflowReadOnlyModal extends Vue {
    @Prop({required: true}) private isVisible!: boolean;
    @Prop({required: true}) private projectHasOrderStatus!: boolean;
    @Prop({required: true}) private label!: Label;

    private modalWidth = ModalWidth;

    private get labelId() {
        if (!this.label) {
            return null;
        }

        return this.label.id;
    }

    private get componentKey() {
        return `${this.labelId}-${String(this.projectHasOrderStatus)}`;
    }

    private onCancel() {
        this.$emit('update:isVisible', !this.isVisible);
    }

    private mounted() {
        Label.insertOrUpdate({
            data: {
                id: GlobalOptions.ALL,
                name: this.$t('Svi') as string,
            },
        });
    }
}
