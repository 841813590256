
import { Component, Watch, Vue } from 'vue-property-decorator';
import ProjectContent from '@/components/views/NewProjectView/ProjectContent.vue';
import ProjectViewSidebar from '@/components/global/sidebar/ProjectViewSidebar.vue';
import { RouteNames } from '@/enums/routes/RouteNames';
import ProjectRepository from '@/repositories/ProjectRepository';
import Project from '@/models/Project';
import ProjectLog from '@/models/ProjectLog';
import CMSUser from '@/models/CMSUser';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import Label from '@/models/Label';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import NewProjectTopBar from '../components/views/project/NewProjectTopBar.vue';
import Client from '@/models/Client';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { LocalStorageService } from '@/services/LocalStorageService';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
import { SessionStorageKeyNames } from '@/enums/global/SessionStorageCommonEndpoints';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import ProjectViewSidebarAttachmentsCard from '@/components/global/sidebar/ProjectViewSidebarAttachmentsCard.vue';
import { redirectToProject, getDeliveryAddress } from '@/helpers/NewProject/NewProjectHelper';

@Component({
    name: 'PhoneProjectView',
    components: { ProjectContent, ProjectViewSidebar, NewProjectTopBar, ProjectViewSidebarAttachmentsCard },
})
export default class PhoneProjectView extends Vue {
    private project: Project | null = null;
    private client: Client | null = null;

    private get projectId() {
        if (this.$route == null) {
            return null;
        }

        return this.$route.params.id;
    }
    private toProject(projectId: number) {
        redirectToProject(projectId);
    }

    private redirectToGoogleMaps() {
        if (this.project) {
            const deliveryAddress = getDeliveryAddress(this.project);
            if (deliveryAddress) {
                const streetNameAndNumber = deliveryAddress.streetNameAndNumber;
                const city = deliveryAddress.city;

                const url = 'https://www.google.com/maps?q=' + streetNameAndNumber + '+' + city;
                window.open(url, '_blank');
                return;
            }
        }

        if (this.client) {
            const streetNameAndNumber = this.client.address?.streetNameAndNumber;
            const city = this.client.address?.city;

            const url = 'https://www.google.com/maps?q=' + streetNameAndNumber + '+' + city;
            window.open(url, '_blank');
        } else {
            alert('Client not found');
        }
    }
    private redirectToAppleMaps() {
        if (this.project) {
            const deliveryAddress = getDeliveryAddress(this.project);
            if (deliveryAddress) {
                const streetNameAndNumber = deliveryAddress.streetNameAndNumber;
                const city = deliveryAddress.city;

                const url = 'http://maps.apple.com/?address=' + streetNameAndNumber + '+' + city;
                window.open(url, '_blank');
                return;
            }
        }

        if (this.client) {
            const streetNameAndNumber = this.client.address?.streetNameAndNumber;
            const city = this.client.address?.city;

            const url = 'http://maps.apple.com/?address=' + streetNameAndNumber + '+' + city;
            window.open(url, '_blank');
        } else {
            alert('Client not found');
        }
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    @Watch('projectId')
    private async handleProjectChange(newVal: any) {
        this.project = null;
        await this.setData();
    }

    private async setData() {
        await Project.fetchDataForProjectView(this.$route.params.id);
        this.project = ProjectRepository.getProjectById(this.$route.params.id);
        this.client = this.project!.projectClient;
        await Promise.all([
            ProjectLog.getById(this.$route.params.id),
            CMSUser.getAll(),
            Label.fetchAvailableLabels(this.$route.params.id),
        ]);
    }

    private changeUserGroup(value: string) {
        const allowedUserGroupIds = this.$store.getters['jwtData/allowedUserGroups'].map((id: number) =>
            String(id)
        ) as string[];
        if (!value || value === this.currentUserGroup?.id || !allowedUserGroupIds.includes(value)) {
            return;
        }
        LocalStorageService.remove(ProjectTypes.Lead as string);
        LocalStorageService.remove(ProjectTypes.Offer as string);
        LocalStorageService.remove(ProjectTypes.Order as string);
        LocalStorageService.remove(ProjectTypes.Admin as string);

        Object.keys(sessionStorage).forEach((key: any) => {
            if (!Object.values(SessionStorageKeyNames).includes(key as SessionStorageKeyNames)) {
                ss.remove(key);
            }
        });

        this.$store.dispatch('jwtData/updateUserGroup', value);
    }

    private async onUpdateProject(settings: {
        newOfferId?: string;
        redirectTo?: ProjectTabValues;
        shouldUpdateOffer?: boolean;
        shouldShowPopup?: boolean;
        popupType?: string;
    }) {
        if (this.project == null) {
            return Promise.reject();
        }

        try {
            if (this.project.label) {
                await Label.delete(this.project.label.id);
            }

            await Project.getById(this.project.projectId);

            this.project = ProjectRepository.getProjectById(this.project.projectId);

            if (this.project == null) {
                return Promise.reject();
            }

            if (settings?.shouldUpdateOffer) {
                EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
            }

            await ProjectLog.getById(this.project.projectId);

            // Show discount information in case order is successful
            if (settings?.shouldShowPopup) {
                switch (settings?.popupType) {
                    case 'celebration':
                        await this.$nextTick();
                        EventBus.$emit(EventBusEvents.showCelebrationModal);
                }
            }
        } catch (e) {
            this.$notification.error({
                message: 'Error',
                description: 'Error updating project',
            });
        }

        return Promise.resolve();
    }

    private async created() {
        if (this.$route && this.$route.params.id == null) {
            this.$router.push({ name: RouteNames.error });
            return;
        }
        if (this.$route && this.$route.query && this.$route.query.ug) {
            this.changeUserGroup(String(this.$route.query.ug));
        } else if (this.$route && this.currentUserGroup?.id) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    ug: this.currentUserGroup?.id,
                },
            });
        }
        await this.setData();

        EventBus.$on(EventBusEvents.updateProject, this.onUpdateProject);
        // this event is legacy, but still used in some methods
        EventBus.$on(EventBusEvents.fetchProjectFromRepository, this.onUpdateProject);
    }

    private async beforeDestroy() {
        EventBus.$off(EventBusEvents.updateProject);
        EventBus.$off(EventBusEvents.fetchProjectFromRepository);
    }
}
