
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import Project from '@/models/Project';
import ProjectLogRepository from '@/repositories/ProjectLogRepository';
import ProjectHistoryModal from '@/components/global/ProjectHistoryModal.vue';

@Component({
    name: 'ProjectViewSidebarHistoryCard',
    components: {
        SidebarCard,
        ProjectHistoryModal,
    },
})
export default class ProjectViewSidebarHistoryCard extends Vue {
    @Prop({ required: true }) private project!: Project;

    private isProjectHistoryModalVisible = false;

    private get historyItems() {
        if (this.project == null) {
            return [];
        }

        return ProjectLogRepository.getAll();
    }

    private get numberOfHistoryItems() {
        return this.historyItems.length;
    }
}
