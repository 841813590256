
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { Icon, Spin, Tabs } from 'ant-design-vue';
import { getProjectTabsConfiguration, onKeyChange } from '@/helpers/NewProject/NewProjectHelper';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import Project from '@/models/Project';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import OfferRepository from '@/repositories/OfferRepository';
import Offer from '@/models/Offer';
import { UserRightsEnum } from '@/enums/global/UserRights';

@Component({
    name: 'ProjectContent',
    components: { Tabs, TabPane: Tabs.TabPane, Spin, Icon },
    inject: [],
})
export default class ProjectContent extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private project!: Project | null;
    @Prop({ required: true }) private canSeeOrders!: boolean;
    @Prop({ required: false }) private basicProjectData!: any;

    private selectedOffer: Offer | null = null;
    private lockEditing: boolean = false;

    private projectTabs = [];
    private activeKey: ProjectTabValues | null = null;
    private isTabLoading = false;
    private loadedKeys = new Set();

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private async updateActiveKey(key: string | Array<string | null>) {
        try {
            await onKeyChange(this.$route, this.activeKey, key);
        } catch (e) {
            return;
        }

        this.activeKey = Number(key);
    }

    private updateSelectedOffer() {
        this.selectedOffer = OfferRepository.getSelectedOffer(this.projectId);
    }

    private get canSeeInquiries() {
        return this.userRights.includes(UserRightsEnum.SEE_INQUIRIES);
    }

    private updateProjectTabs() {
        // @ts-ignore
        this.projectTabs = getProjectTabsConfiguration(this.canSeeInquiries, this.canSeeOrders);
    }

    private async updateActiveTab() {
        if (this.$route && this.$route.query && this.$route.query.initialTab) {
            await this.updateActiveKey(this.$route.query.initialTab);
        } else {
            this.activeKey = ProjectTabValues.Basic;
        }
    }

    private updateActiveKeyWithoutRoute() {
        this.activeKey = Number(this.$route.query.initialTab);
    }

    private async created() {
        this.updateProjectTabs();

        EventBus.$on(EventBusEvents.fetchSelectedOfferFromRepository, this.updateSelectedOffer);

        this.updateActiveKeyWithoutRoute();
        if (this.project) {
            this.lockEditing =
                String(this.project.userGroupId) !== String(this.$store.getters['jwtData/currentUserGroup']) &&
                !(this.project.state === 'request' && String(this.$store.getters['jwtData/currentUserGroup']) === '1');
        }
    }
    private beforeDestroy() {
        EventBus.$off(EventBusEvents.fetchSelectedOfferFromRepository, this.updateSelectedOffer);
    }

    @Watch('project.state')
    private async onProjectStateChange(state: string) {
        if (state !== 'order') {
            return;
        }

        this.updateProjectTabs();
    }
}
