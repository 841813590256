
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { RouteNames } from '@/enums/routes/RouteNames';
import { CorrespondingProjectSettings } from '@/interfaces/components/project/CorrespondingProjectSettings';
import { ProjectNavigationDirection } from '@/enums/components/Project/ProjectNavigationDirection';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';

@Component({
    name: 'ProjectActionButtons',
    components: {},
})
export default class ProjectActionButtons extends Vue {
    @Action('tableData/getCorrespondingProjectId')
    private getCorrespondingProjectId!: ({
        currentProjectId,
        direction,
    }: CorrespondingProjectSettings) => Promise<null | string>;
    @Getter('tableData/sortedProjectsObject')
    private sortedProjectsObject!: boolean;
    @Getter('projectLoadingState/isProjectLoading')
    private isProjectLoading!: boolean;
    @Action('tableData/isProjectInList')
    private isProjectInList!: (currentProjectId: string) => Promise<boolean>;
    @Action('tableData/isPreviousButtonDisabled')
    private isPreviousButtonDisabled!: (currentProjectId: string) => Promise<boolean>;
    @Action('tableData/isNextButtonDisabled')
    private isNextButtonDisabled!: (currentProjectId: string) => Promise<boolean>;
    @Action('projectLoadingState/setAllLoadingState')
    private setAllLoadingState!: (newState: boolean) => void;

    @Prop({ required: true }) private projectId!: string;

    private shouldPreviousButtonBeDisabled = false;
    private shouldNextButtonBeDisabled = false;

    private get shouldShowComponent() {
        return !(Object.values(this.sortedProjectsObject).length <= 0);
    }

    private async onNextClick() {
        const nextProjectId = await this.getCorrespondingProjectId({
            currentProjectId: this.projectId,
            direction: ProjectNavigationDirection.NEXT,
        });

        this.setAllLoadingState(true);

        if (nextProjectId == null) {
            this.$notification.error({
                message: this.$t('Došlo je do greške') as string,
                description: ``,
            });
            this.setAllLoadingState(false);
            return;
        }

        this.$router.push({
            name: RouteNames.project,
            params: {
                id: nextProjectId,
            },
            query: {
                initialTab: `${ProjectTabValues.Basic}`,
            },
        });
    }

    private async onPrevClick() {
        const previousProjectId = await this.getCorrespondingProjectId({
            currentProjectId: this.projectId,
            direction: ProjectNavigationDirection.PREVIOUS,
        });

        this.setAllLoadingState(true);

        if (previousProjectId == null) {
            this.$notification.error({
                message: this.$t('Došlo je do greške') as string,
                description: ``,
            });
            this.setAllLoadingState(false);
            return;
        }

        this.$router.push({
            name: RouteNames.project,
            params: {
                id: previousProjectId,
            },
            query: {
                initialTab: `${ProjectTabValues.Basic}`,
            },
        });
    }

    @Watch('projectId', { immediate: true })
    private async onProjectIdChange() {
        if (!(await this.isProjectInList(this.projectId))) {
            this.$store.commit('tableData/clearProjectNavigationSettings');
        }

        this.shouldPreviousButtonBeDisabled = await this.isPreviousButtonDisabled(this.projectId);
        this.shouldNextButtonBeDisabled = await this.isNextButtonDisabled(this.projectId);
    }
}
