
import { Component, Prop, Vue } from 'vue-property-decorator';
import Client from '@/models/Client';
import Project from '@/models/Project';
import ProjectActionButtons from '@/components/views/project/ProjectActionButtons.vue';
import ProjectDynamicInfoBar from '@/components/views/project/ProjectDynamicInfoBar.vue';
import ProjectStatusSwitcher from '@/components/views/project/ProjectStatusSwitcher.vue';
import HelpPage from '@/components/global/HelpPage.vue';
import { RouteConfig } from 'vue-router';

@Component({
    name: 'NewProjectTopBar',
    components: {
        ProjectDynamicInfoBar,
        ProjectActionButtons,
        ProjectStatusSwitcher,
        HelpPage,
    },
})
export default class NewProjectTopBar extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private client!: Client | null;
    @Prop({ required: true }) private project!: Project | null;
    @Prop({ required: false }) private basicStateSpecificId!: string;
    @Prop({ required: false }) private basicProjectData!: any;
    @Prop({ required: false }) private beforeRoute!: RouteConfig;

    private goBack(): void {
        if (this.beforeRoute) {
            this.$router.push({
                ...this.beforeRoute,
            });
        } else {
            this.$router.go(-1);
        }
    }
}
